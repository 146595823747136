<template>
  <div class="row">
    <div class="col-12 mt-4 pt-2">
      <StripeCardInput v-if="!hidePayCard && elements" :elements="elements" v-model:card="card" v-model:cardErrors="cardErrors" :hide="hidePayCard" />
      <StripePaymentButton @makePayment="makePayment" :isLoading="isLoadingValue" :hide="hidePayButton" v-if="!hidePayButton" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default ({
  components: {
    StripeCardInput: defineAsyncComponent(() => import('../components/StripeCardInput.vue')),
    StripePaymentButton: defineAsyncComponent(() => import('../components/StripePaymentButton.vue'))
  },
  name: 'StripePaymentSection',
  props: ['isUkPayment', 'isRecurringPayment', 'ukDailySadaqahSubscription', 'turnstileReturn', 'isLoading', 'hidePayCard', 'hidePayButton', 'checkCard'],
  emits: ['stripeContinue', 'update:isLoading', 'recurringComplete', 'sadaqahComplete'],
  data () {
    return {
      stripeAPIToken: process.env.VUE_APP_STRIPE_KEY,
      stripe: '',
      elements: '',
      card: {
        cardNumber: '',
        cardExpiryDate: '',
        cardCvc: ''
      },
      cardErrors: {
        cardNumber: '',
        cardExpiryDate: '',
        cardCvc: ''
      }
    }
  },
  mounted () {
    if (this.stripe) {
      this.configureStripe()
    } else {
      this.includeStripe('js.stripe.com/v3/', function () {
        this.configureStripe()
      }.bind(this))
    }
  },
  watch: {
    isUkPayment () {
      if (this.isUkPayment) {
        this.confirmPayment()
      }
    },
    isRecurringPayment () {
      if (this.isRecurringPayment) {
        this.initiateRecurringSubscription()
      }
    },
    ukDailySadaqahSubscription () {
      if (this.ukDailySadaqahSubscription && this.ukDailySadaqahSubscription.subscriptionReference) {
        this.initiateDailySadaqahSubscription()
      }
    },
    checkCard () {
      if (this.checkCard) {
        this.makePayment()
      }
    }
  },
  computed: {
    ...mapGetters(['stripeClientSecret', 'stripeSubscriptionClientSecret']),
    isLoadingValue: {
      get () {
        return this.isLoading
      },
      set (value) {
        this.$emit('update:isLoading', value)
      }
    }
  },
  methods: {
    ...mapActions(['cancelStripePayment', 'initiateStripeSubscription']),
    /* Includes Stripe.js dynamically */
    includeStripe (URL, callback) {
      const documentTag = document
      const tag = 'script'
      const object = documentTag.createElement(tag)
      const scriptTag = documentTag.getElementsByTagName(tag)[0]
      object.src = '//' + URL
      if (callback) {
        object.addEventListener('load', function (e) {
          callback(null, e)
        }, false)
      }
      scriptTag.parentNode.insertBefore(object, scriptTag)
    },
    configureStripe () {
      // eslint-disable-next-line no-undef
      this.stripe = Stripe(this.stripeAPIToken)
      this.elements = this.stripe.elements()
    },
    checkCardDetails () {
      const invalidCardNumber = this.card.cardNumber._invalid
      const invalidCardExpiryDate = this.card.cardExpiryDate._invalid
      const invalidCardCvc = this.card.cardCvc._invalid

      const emptyCardNumber = this.card.cardNumber._empty
      const emptyCardExpiryDate = this.card.cardExpiryDate._empty
      const emptyCardCvc = this.card.cardCvc._empty

      const isInvalid = invalidCardNumber || invalidCardExpiryDate || invalidCardCvc
      const isEmpty = emptyCardNumber || emptyCardExpiryDate || emptyCardCvc
      if (emptyCardNumber) {
        this.cardErrors.cardNumber = 'Required'
      } else if (invalidCardNumber) {
        this.cardErrors.cardNumber = 'Invalid'
      }
      if (emptyCardExpiryDate) {
        this.cardErrors.cardExpiryDate = 'Required'
      } else if (invalidCardExpiryDate) {
        this.cardErrors.cardExpiryDate = 'Invalid'
      }
      if (emptyCardCvc) {
        this.cardErrors.cardCvc = 'Required'
      } else if (invalidCardCvc) {
        this.cardErrors.cardCvc = 'Invalid'
      }
      if (isInvalid || isEmpty) {
        return false
      } else {
        return true
      }
    },
    async makePayment () {
      const validCard = await this.checkCardDetails()
      if (validCard) {
        this.isLoadingValue = true
        console.log('SAVE')
        this.$emit('stripeContinue')
      }
    },
    async confirmPayment () {
      this.isLoadingValue = true
      const ret = await this.stripe.confirmCardPayment(this.stripeClientSecret, {
        payment_method: {
          card: this.card.cardNumber
        }
      }).then(function (result) {
        console.log(result)
        return result
        // Handle result.error or result.paymentIntent
      })
      console.log('RESULT, ret', ret)
      this.isLoadingValue = false
      this.$router.push('/paymentcompleteUk')
    },
    async initiateDailySadaqahSubscription () {
      this.isLoadingValue = true
      if (this.ukDailySadaqahSubscription && this.ukDailySadaqahSubscription.subscriptionReference && this.turnstileReturn) {
        console.log('this.ukDailySadaqahSubscription', this.ukDailySadaqahSubscription, this.turnstileReturn)
        const theData = {
          transactionRef: this.ukDailySadaqahSubscription.subscriptionReference,
          token: this.turnstileReturn
        }
        const stripeRet = await this.initiateStripeSubscription(theData)
        if (stripeRet && stripeRet.result && (stripeRet.result.code === 200 || stripeRet.result.code === '200')) {
          const ret = await this.stripe.confirmCardSetup(this.stripeSubscriptionClientSecret, {
            payment_method: {
              card: this.card.cardNumber
            }
          }).then(function (result) {
            console.log(result)
            return result
            // Handle result.error or result.paymentIntent
          })
          console.log('RESULT, ret', ret)
          this.isLoadingValue = false
          this.$emit('sadaqahComplete', this.ukDailySadaqahSubscription)
          // this.$router.push(`/recurringpaymentcompleteUk/${this.ukDailySadaqahSubscription.subscriptionReference}/${this.ukDailySadaqahSubscription.recurringDonationID}`)
        }
      }
    },
    async initiateRecurringSubscription () {
      this.isLoadingValue = true
      if (this.isRecurringPayment && this.isRecurringPayment.subscriptionReference && this.turnstileReturn) {
        console.log('this.isRecurringPayment', this.isRecurringPayment, this.turnstileReturn)
        const theData = {
          transactionRef: this.isRecurringPayment.subscriptionReference,
          token: this.turnstileReturn
        }
        const stripeRet = await this.initiateStripeSubscription(theData)
        if (stripeRet && stripeRet.result && (stripeRet.result.code === 200 || stripeRet.result.code === '200')) {
          const ret = await this.stripe.confirmCardSetup(this.stripeSubscriptionClientSecret, {
            payment_method: {
              card: this.card.cardNumber
            }
          }).then(function (result) {
            console.log(result)
            return result
            // Handle result.error or result.paymentIntent
          })
          console.log('RESULT, ret', ret)
          this.isLoadingValue = false
          this.$emit('recurringComplete')
        }
      }
    },
    cancelPayment () {
      this.card.cardNumber.clear()
      this.card.cardNumber.destroy()
      this.card.cardExpiryDate.clear()
      this.card.cardExpiryDate.destroy()
      this.card.cardCvc.clear()
      this.card.cardCvc.destroy()
      this.cancelStripePayment()
    }
  }
})
</script>
